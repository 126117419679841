*{
    font-family: 'Source Code Pro', monospace;
    /* border: 1px solid yellow; */
}
/* 
.css-version::after{
    position: relative;
    display: block;
    content: "v12";
    color: red;
    background: white;
    width: 5rem;
    height: 2rem;
} */

.store-card *{
    /* border: 1px solid yellow; */
}

body{
    background-image: url('./assets/ChiptosXMintBG.jpeg');
    background-position: center center;
    background-size: cover;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    /* border: 2px solid red; */
}

.claim-free-btn{
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    padding: .5rem 1rem;
}

.tx-link{
    background: green;
    color: white;
    padding: .25rem .5rem;
    margin-top: .5rem;
    border-radius: .25rem;
    transition: .3s;
}
.tx-link:hover{
    background: white;
    color: green;
}

.claim-free-btn *{
    padding: 0;
    margin: 0;
}

html{
    position: relative;
    display: block;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    /* border: 2px solid yellow; */
}

#root{
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
}

.wrong-chain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem;
    margin: 2rem;
    text-align: center;
    background: #222c;
    color: white;
    border-radius: .5rem;
}

.wrong-chain button{
    background: #444c;
    padding: 1rem 2rem;
    margin-top: 1rem;
}

.main-container{
    /* border: 1px solid red; */
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;      /*  Added this in addition to `body:{overflow: hidden}` */
}

.content{
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.main-logo{
    height: 3rem;
    width: 10rem;
    /* border: 1px solid red; */
    background-image: url('./assets/ChiptosLogoWhite_3.png');
    pointer-events: none;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: auto;
}

.mobile-menu{
    display: none;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    background: #2226;
    height: 100vh;
    transform: translateX(100vw);
    -webkit-transform: translateX(100vw);
    -moz-transform: translate(100vw);
    -ms-transform: translate(100vw);
    -o-transform: translate(100vw);

    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: .5s;
    /* background-image: url('https://placekitten.com/1920/1080');
    background-position: 100% center;
    background-size: cover;
    background-attachment:fixed; */
    z-index: 3;
    display: none;
}

.mobile-menu .bg{
    display: none;
    background-image: url('./assets/ChiptosXMintBG.jpeg');
    background-position: center center;
    background-size: cover;
    background-attachment:fixed;

    /* filter: grayscale(1); */
    transition: .5s;
    height: 100vh;
    width: 100vw;
    position: fixed;
    pointer-events: auto;
    top:0;
    left:0;
    min-width:100vw;
    transform: translateX(-100vw);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 5vw;
    /* padding-top: 10rem; */
}
.menu-bg-fade{
    background: #2226;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top:0;
    left:0;
    z-index: 1;
}
.menu-button-layer{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    z-index: 2;
}
.nav-link{
    background: #2226;
    color: white;
    padding: 0 2rem;
    height: 2.5rem;
    min-width: 10rem;
    display: flex;
    align-items: center;
    border-radius: 1.25rem;
    font-size: 1.2rem;
    transition: .3s;
}
.nav-link:hover{
    background: #000;
}

.nav-icon-row{
    /* border: 1px solid black; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
}

.nav-icon{
    height: 4rem;
    width: 4rem;
    border-radius: 2rem;
    background-repeat: no-repeat;
}

.etherscan-nav{
    background-image: url('./assets/etherscan.jpg');
    background-position: center center;
    background-size: 4.2rem 4.2rem;
    filter: grayscale(1)
}

.opensea-nav{
    background-image: url('./assets/opensea.png');
    background-position: center center;
    background-size: 4rem 4rem;
    filter: grayscale(1)
}

.looksrare-nav{
    height: 4rem;
    width: 4rem;
    background-image: url('./assets/looksrare2.png');
    background-position: center center;
    background-size: cover;
    filter: grayscale(1)
}

.twitter-nav{
    background-image: url('./assets/iconmonstr-twitter-1.svg');
    background-position: center center;
    background-size: 3.2rem 3.2rem;
    background-repeat: no-repeat;
    filter: invert(.5);
}

.discord-nav{
    background-image: url('./assets/iconmonstr-discord-1.svg');
    background-position: center center;
    background-size: 3.1rem 3.1rem;
    background-repeat: no-repeat;
    filter: invert(.5);
}

.mobile-menu .close-button{
    position: absolute;
    top: 1.2rem;
    left: 1.5rem;
    height: 3rem;
    width: 3rem;
    padding:0;
    border-radius: 50%;
    background: none;
    background-image: url('./assets/iconmonstr-x-mark-2.svg');
    background-repeat: no-repeat;
    background-position: center center;
    filter: invert(1);
}




/* STORE */
.store-card{
    /* border: 1px solid orange; */
    border-radius: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #26242c;
    transform: translateY(40vh);
    transition: 1s;
}

.store-info{
    /* border: 1px solid red; */
    background: #0d0c0f;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    min-width: 20rem;
}


.store-icon{
    width:12rem;
    height: 12rem;
    background: #222;
    border-radius: 50%;
    margin-top: -6rem;
    background-image: url('./assets/Logo.jpeg');
    background-size: contain;
    background-position: center center;
}

.store-stats{
    /* border: 1px solid green; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.store-stat p{
    margin:0;
    text-align: center;
}

.store-stat h5{
    margin:.25rem;
    margin-top: 1rem;
    font-size: 1.2rem;
}

.store-links{
    display: flex;
    width: 80%;
    max-width: 10rem;
    justify-content: space-between;
    padding: 1rem 1rem;
    padding-bottom: 0;
}

.store-link{
    background: #222;
    transition: .3s;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    cursor:pointer;
    /* filter: grayscale(1); */
    z-index: 4;
}

.store-link:hover{
    /* box-shadow:  0 0 4px 4px white; */
    filter: grayscale(0)
}

.etherscan{
    background-image: url('./assets/etherscan.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 120% 120%;
}

.opensea{
    background-image: url('./assets/opensea.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 115% 115%;
}

.looksrare{
    background-image: url('./assets/looksrare2.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 115% 135%;
}


.store-row-container{
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: stretch;
    min-width: 50vw;
    /* border: 1px solid green; */
}

.store-row{
    /* border: 1px solid yellow; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 2rem; */
    height: 33.3%;
    border-top: 1px solid #38373c;
}
.store-row:nth-child(1){
    border: 0px;
}

.store-sub-row{
    display: flex;
}

.store-row-heading{
    font-size: 1.6rem;
    margin-bottom: 2rem;
    margin-top:0;
}

.input-container{
    display: flex;
    margin-right: 2rem;
    /* border: 1px solid red; */
}
.input-container .value{
    /* background: white; */
    width: 4rem;
    color: white;
    margin: 0 .5rem;
    border-radius: .25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    font-size: 1.8rem;
}
.subtext{
    padding:0;
    margin:0;
    /* margin-left: 2.5rem; */
    font-size: .8rem;
    margin-top: 2rem;
    /* margin-bottom: -1rem; */
    color: #ccc;
}

button{
    background: rgb(49, 84, 226);
    padding: 0 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 2rem;
    font-size: 1.2rem;
    border-radius: 1rem;
    cursor: pointer;
    transition: .3s;
}
button p{
    display: inline-block;
}
button:hover{
    background: rgb(104, 128, 223);
}

.round-button{
    height: 2rem;
    width: 2rem;
    padding: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}




/* FOOTER */

.footer-link{
    margin-right: 10px;
    height: 2rem;
    width: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    border-radius: 1rem;
    cursor: pointer;

}
.footer-link:hover{
    background: #fff;
    color: #222;

}

.icon{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin-left: 1rem;
    transition: .3s;
}

.twitter-icon{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60% 60%;
    filter: invert(1);
    background-image: url('./assets/iconmonstr-twitter-1.svg')
}
.twitter-icon:hover{
    background: white;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60% 60%;
    filter: invert(0);
    background-image: url('./assets/iconmonstr-twitter-1.svg')
}

.discord-icon{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60% 60%;
    filter: invert(1);
    background-image: url('./assets/iconmonstr-discord-1.svg')
}
.discord-icon:hover{
    background: white;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60% 60%;
    filter: invert(0);
    background-image: url('./assets/iconmonstr-discord-1.svg')
}

.footer{
    display: flex;
    /* border: 1px solid purple; */
}

/*~ MOBILE LANDSCAPE                                                                                               */
@media (max-width: 1200px) and (orientation: landscape){

    /* *{
        border: 1px solid yellow
    } */

    .wrong-chain{
        padding: 1rem 2rem;
        padding-top: 0;
        margin: 0rem;
        margin-top: -4rem;
        text-align: center;
        background: #222c;
        color: white;
        border-radius: .5rem;
    }

    .wrong-chain h2{
        font-size: 1.8rem;
    }
    .wrong-chain h4{
        font-size: 1.4rem;
    }

    .footer{
        display: none;
    }

    .claim-free-btn{
        /* border: 1px solid blue; */
        display: flex;
        flex-direction: row;
        padding: .5rem 1rem;
    }


    body{
        /* background: blue; */
    }

    .store-card{
        /* border: 1px solid yellow; */

    }

    .mobile-menu {
        display: none;
        flex-direction: row;
        /* border: 1px solid purple; */
    }


    .menu-button-layer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* border: 1px solid red; */
    }

    .nav-link-row{
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        /* border: 1px solid green; */
    }

    .nav-icon-row{
        /* border: 1px solid green; */
        display: flex;
        justify-content: center;
    }

    .nav-icon-row a{
        margin: 1rem;
    }

    .main-logo{
        /* background:#fff; */
        margin-top: 1rem;
        width: 3rem;
        background: none;
        background-image: url('./assets/Logo.jpeg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 3rem 3rem;
        border-radius: 1.5rem;
        pointer-events: none;
        /* cursor: pointer; */
        filter: invert(1)
    }

    .store-card h4{
        margin-top: 0;
    }


    .store-stats{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 1rem;
    }

    .store-stat h5{
        font-size: 1rem;
        margin-top: .1rem;
    }

    .subtext{
        margin-top: .5rem;
    }

    .store-row-heading{
        font-size: 1.2rem;
        margin-bottom: .5rem;
    }


}


/*~ MOBILE PORTRAIT                                                                                                 */
@media (max-width: 1200px) and (orientation: portrait){
    .footer{
        display: none;
    }

    .wrong-chain{
        padding: 1rem 2rem;
        padding-top: 0;
        margin: 1rem;
        margin-top: -4rem;
        text-align: center;
        background: #222c;
        color: white;
        border-radius: .5rem;
    }

    .wrong-chain h2{
        font-size: 1.8rem;
    }
    .wrong-chain h4{
        font-size: 1.4rem;
    }

    .store-card {
      flex-direction: column;
      transform: translateY(100vh);
      transition: 1s;
      /* transition-delay: 1s; */
    }

    .store-info{
        border-bottom-left-radius: 0;
        border-top-right-radius: 1rem;
    }

    .main-logo{
        /* background:#fff; */
        width: 3rem;
        position: absolute;
        top: 1.2rem;
        left: 1.5rem;
        height: 3rem;
        width: 3rem;
        background: none;
        background-image: url('./assets/Logo.jpeg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 3rem 3rem;
        pointer-events: auto;
        cursor: pointer;
        filter: invert(1);
        border-radius: 1.5rem;
    }
    .mobile-menu{
        display: none;
        justify-content: center;
        align-items: center;
        /* border: 1px solid yellow; */
    }



    .menu-button-layer{
        margin: 0 auto;
        margin-top: 10vh;
        padding-bottom: 10vh;
        display: flex;
        /* border: 2px solid orange; */
        max-width: 30rem;
    }

    .nav-link-row{
        width: 100%;
    }

    .nav-icon-row a{
        max-width: 12vw;
        max-height: 12vw;
    }

    .store-card h4{
        margin-top: 0;
    }


    .store-stats{
        display: flex;
        /* flex-direction: row; */
        justify-content: space-between;
        margin: 0 1rem;
    }

    .store-stat h5{
        font-size: 1rem;
        margin-top: .1rem;
    }

    .subtext{
        margin-top: .5rem;
    }

    .store-row-heading{
        font-size: 1.2rem;
        margin-bottom: .5rem;
    }

    .store-row{
        padding: .5rem;
    }

    .store-links{
        padding: .2rem .2rem;
        padding-bottom: 0;
    }

    .store-info{
        padding: .5rem;
    }

    .store-icon{
        width: 8rem;
        height: 8rem;
        margin-top: -2rem;
    }
    
}
@media (max-height: 1000px) and (orientation: portrait){
    body{
        /* background: green; */
        padding: 0 .5rem;
    }

    .store-icon{
        display: none;
    }

    .store-info h4{
        font-size: 1.4rem;
    }
    .store-row-heading{
        text-align: center;
    }
}